import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  handleError,
  deleteApi,
  fetchApi,
  patchApi,
  postApi,
  putApi,
} from 'core/utils/api';
import { Socket, io } from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { push } from 'connected-react-router';
import actions from './actions';
import {
  FetchApprovedMessageSagaProps,
  FetchChannelSagaProps,
  FetchChannelsByStudentProfileSagaProps,
  FetchChannelsSagaProps,
  FetchChatClassroomsSagaProps,
  FetchChatConfirmedResponsiblesSagaProps,
  FetchChatPendingCountSagaProps,
  FetchChatSearchUsersSagaProps,
  FetchChatStudentProfilesSagaProps,
  FetchChatsSagaProps,
  FetchCurrentChatSagaProps,
  FetchDeletedMessageSagaProps,
  FetchEditMessageSagaProps,
  FetchMessageSagaProps,
  FetchMessagesSagaProps,
  FetchSchoolUsersSagaProps,
  FetchTicketDetailSagaProps,
  FetchTicketPendingCountSagaProps,
  FetchTicketStudentChannelsSagaProps,
  FetchTicketsSagaProps,
  FetchUnapprovedMessageSagaProps,
  Message,
  OmniChannelState,
  Recipient,
  SetApproveMessageSagaProps,
  SetCreateNewChannelSagaProps,
  SetCreateNewChatSagaProps,
  SetCreateNewChatUserSagaProps,
  SetCreateNewMessageSagaProps,
  SetCreateNewTicketBySchoolProps,
  SetDeleteChannelActionSagaProps,
  SetDeleteMessageActionSagaProps,
  SetEditChannelActionSagaProps,
  SetEditMessageActionSagaProps,
  SetMessagesMarkReadStatusSagaProps,
  SetRedirectChannelPageSagaProps,
  SetRedirectMessageSagaProps,
  SetStartAttendanceSagaProps,
  SetTransferTicketActionSagaProps,
  SetUpdateChannelsOrderSagaProps,
  SocketEmitParams,
  SocketEnterChatRoomParams,
  SocketLeaveChatRoomParams,
} from './types';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { queryUrlFiltered } from 'core/helper/message/omni/queryUrlFiltered';
import { reverseArray } from 'core/utils/reverseArray';
import { extractErrorsFromUnauthorizedRequest } from 'core/utils/request';
import { ERROR_REDIRECT_MESSAGE } from 'core/constants';
import {
  normalizeTicketDetail,
  normalizeTicketParams,
} from 'core/helper/message/omni/normalizeTicketParams';
import { queryUrlFetchTickets } from 'core/helper/message/omni/queryUrlFetchTickets';
import { queryUrlFetchTicketsCount } from 'core/helper/message/omni/queryUrlFetchTicketsCount';
import { normalizeCountUnreadParams } from 'core/helper/message/omni/normalizeCountUnreadParams';
import { normalizeStartOrCloseTicketParams } from 'core/helper/message/omni/normalizeStartOrCloseTicketParams';
import { normalizeNewMessageParams } from 'core/helper/message/omni/normalizeNewMessageParams';
import {
  normalizeAttendingHoursRequest,
  normalizeChannels,
  normalizeCurrentChannel,
} from 'core/helper/message/omni/normalizeChannelParams';

import i18n from 'config/i18n';

const socketEvents = [
  'new-message',
  'approve-message',
  'unapprove-message',
  'delete-message',
  'edit-message',
];

function socketEventListener(socket: Socket) {
  return (emit: (params: SocketEmitParams) => void) => {
    socketEvents.map((eventName) => {
      socket.on(
        eventName,
        (messageId, messageApprovalStatus, approvalStatus) => {
          const params = {
            messageId,
            messageApprovalStatus,
            approvalStatus,
            eventName,
          };
          emit(params);
        }
      );
    });

    return () => {
      socketEvents.map((eventName) => socket.off(eventName));
    };
  };
}

function* socketListenerSaga() {
  const { socket } = yield select((state) => state.omniChannel);

  const channel = eventChannel(socketEventListener(socket));

  const eventActionMap = {
    'new-message': actions.FETCH_MESSAGE_REQUEST,
    'approve-message': actions.FETCH_APPROVED_MESSAGE_REQUEST,
    'unapprove-message': actions.FETCH_UNAPPROVED_MESSAGE_REQUEST,
    'delete-message': actions.FETCH_DELETED_MESSAGE_REQUEST,
    'edit-message': actions.FETCH_EDIT_MESSAGE_REQUEST,
  };

  try {
    while (true) {
      const event = yield take(channel);

      const { eventName, messageId, approvalStatus } = event;

      const actionType = eventActionMap[eventName];
      if (actionType) {
        yield put({
          type: actionType,
          messageId,
          approvalStatus,
        });
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}
export function* fetchChannelsSaga({ channelId }: FetchChannelsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels.json`
    );

    const channels = normalizeChannels(data);

    yield put({
      type: actions.FETCH_CHANNELS_SUCCESS,
      channelId,
      channels,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_channels'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchInitialChannelFormSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: icons } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/list_channel_icons.json`
    );

    const { history: classrooms } = yield call(
      fetchApi,
      `/${dataArea}/messages/classrooms.json`
    );

    yield put({
      type: actions.FETCH_INITIAL_CHANNEL_FORM_SUCCESS,
      icons,
      classrooms,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchSchoolUsersSaga({
  classroomsIds,
}: FetchSchoolUsersSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const schoolUsers = yield call(
      postApi,
      `/${dataArea}/messages/school_users.json`,
      { classroom_ids: classroomsIds }
    );

    yield put({
      type: actions.FETCH_SCHOOL_USERS_SUCCESS,
      schoolUsers,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_school_user'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setMessagesMarkReadStatusSaga({
  params,
}: SetMessagesMarkReadStatusSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { status, channelId, chatId } = params;

    const { data: chat, included } = yield call(
      patchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/${status}`
    );

    const toastMessage = {
      mark_as_read: i18n.t(
        'messages:omni_channel.request_toasts.chat_mark_read'
      ),
      mark_as_unread: i18n.t(
        'messages:omni_channel.request_toasts.chat_mark_unread'
      ),
    };

    yield put({
      type: actions.SET_MESSAGES_MARK_READ_STATUS_SUCCESS,
      chat,
      included,
      toast: buildToast(
        toastMessage[status],
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CHAT_PENDING_COUNT_REQUEST,
      params: {
        channelId: channelId,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_chat_mark_read'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setRedirectChannelPageSaga({
  params,
}: SetRedirectChannelPageSagaProps) {
  try {
    const { channelId, chatId, canAccessRedirect } = params;

    const { dataArea } = yield select((state) => state.root);

    if (canAccessRedirect) {
      const { data: chat, included } = yield call(
        fetchApi,
        `/${dataArea}/messages/channels/${channelId}/chats/${chatId}`
      );

      yield put({
        type: actions.SET_REDIRECT_CHANNEL_PAGE_SUCCESS,
        channelId,
        chatId,
        chat,
        included: included[0],
      });
    } else {
      throw Error;
    }
  } catch (error) {
    yield put({
      type: actions.SET_REDIRECT_CHANNEL_ERROR,
      toast: buildToast(
        i18n.t(
          'messages:omni_channel.request_toasts.info_redirect_channel_page'
        ),
        ToastTypes.info,
        toastCss(ToastTypes.info)
      ),
    });
  }
}

export function* setRedirectMessageRequestSaga({
  params,
}: SetRedirectMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { targetChannelId, targetChannelName, channelId, chatId, messageId } =
      params;

    const { data: newMessage } = yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages/${messageId}/redirect`,
      {
        target_channel: Number(targetChannelId),
      }
    );

    yield put({
      type: actions.SET_REDIRECT_MESSAGE_SUCCESS,
      newMessage,
      toast: buildToast(
        i18n.t(
          'messages:omni_channel.request_toasts.success_redirect_message',
          { channelName: targetChannelName }
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CHAT_PENDING_COUNT_REQUEST,
      params: {
        channelId: targetChannelId,
      },
    });
  } catch (error) {
    const errors = extractErrorsFromUnauthorizedRequest(error.response);

    const message = errors.find((index) => index === ERROR_REDIRECT_MESSAGE);

    const errorMessage = message
      ? i18n.t(
          'messages:omni_channel.request_toasts.error_redirect_message_family'
        )
      : i18n.t('messages:omni_channel.request_toasts.error_redirect_message');

    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        errorMessage,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setUpdateChannelsOrderSagas({
  channelsIds,
}: SetUpdateChannelsOrderSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(putApi, `/${dataArea}/messages/channels/custom_orders`, {
      custom_order: channelsIds,
    });

    yield put({
      type: actions.SET_UPDATE_CHANNELS_ORDER_SUCCESS,
      toast: buildToast(
        i18n.t(
          'messages:omni_channel.request_toasts.success_change_channel_order'
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'messages:omni_channel.request_toasts.error_change_channel_order'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setApproveMessageSaga({ params }: SetApproveMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, chatId, messageId, approveStatus } = params;

    const { data: message, included } = yield call(
      patchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages/${messageId}/${approveStatus}`
    );

    yield put({
      type: actions.SET_APPROVE_MESSAGE_SUCCESS,
      message,
      included: included[0],
      approveStatus,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_aprove_message'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCreateNewChannelSaga({
  params,
}: SetCreateNewChannelSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const newAttendingHours = normalizeAttendingHoursRequest(
      params?.attending_hours
    );

    yield call(postApi, `/${dataArea}/messages/channels`, {
      ...params,
      attending_hours: newAttendingHours,
    });

    yield put(push(`/${dataArea}/messages`));

    yield put({
      type: actions.SET_CREATE_NEW_CHANNEL_SUCCESS,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.success_new_channel', {
          name: params.name,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_new_channel'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCreateNewChatSaga({ params }: SetCreateNewChatSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { attachment, channelId, message, recipients } = params;

    const recipientsRequest = recipients.map((recipient: Recipient) => {
      const kind = recipient.type !== 'family' ? 'private' : recipient.type;

      return {
        kind,
        student_profile_id:
          recipient.type === 'student_profile'
            ? recipient.id
            : recipient.studentProfileId,
        user: {
          id: recipient.id,
          type:
            recipient.type === 'family' ? 'student_profile' : recipient.type,
        },
      };
    });

    const data = new FormData();

    data.append('resource', 'chat');
    data.append('message[message]', message.trim() || '');
    data.append('recipients', JSON.stringify(recipientsRequest));

    if (attachment) {
      data.append(
        'message[document]',
        attachment.getUploadedFile(),
        attachment.getName()
      );
    }

    yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/new_messages`,
      data
    );

    yield put(push(`/${dataArea}/messages`));

    yield put({
      type: actions.SET_CREATE_NEW_CHAT_SUCCESS,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.success_create_chat'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_create_chat'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCreateNewChatUserSaga({
  params,
}: SetCreateNewChatUserSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, message, recipient } = params;

    const data = new FormData();

    data.append('resource', 'chat');
    data.append('message[message]', message.trim() || '');
    data.append('recipients', JSON.stringify(recipient));

    const { data: newMessage } = yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/new_messages`,
      data
    );

    const chatId = newMessage[0].relationships.chat.data.id;

    const { data: chat, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}`
    );

    yield put({
      type: actions.SET_CREATE_NEW_CHAT_USER_SUCCESS,
      chat,
      included: included[0],
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_create_chat'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCreateNewMessageSaga({
  params,
}: SetCreateNewMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, attachedMessageId } = params;

    const data = normalizeNewMessageParams(params);

    const { data: lastMessage, included } = yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/new_messages`,
      data
    );

    if (attachedMessageId)
      yield put({
        type: actions.SET_REPLY_MESSAGE_SUCCESS,
      });

    yield put({
      type: actions.SET_CREATE_NEW_MESSAGE_SUCCESS,
      lastMessage: lastMessage[0],
      included: included[0],
    });

    if (params.chatKind !== 'ticket')
      yield put({
        type: actions.FETCH_CHAT_PENDING_COUNT_REQUEST,
        params: {
          channelId: channelId,
        },
      });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_create_message'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCreateNewTicketBySchoolSaga({
  params,
}: SetCreateNewTicketBySchoolProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, title, description, recipients } = params;

    const requestData = {
      channel_id: channelId,
      title,
      description,
      recipients,
    };

    yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/tickets`,
      requestData
    );

    yield put(push(`/${dataArea}/messages`));

    yield put({
      type: actions.SET_CREATE_NEW_TICKET_BY_SCHOOL_SUCCESS,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.success_create_ticket'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_create_ticket'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setDeleteChannelSaga({
  channelId,
}: SetDeleteChannelActionSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: removedChannel } = yield call(
      deleteApi,
      `/${dataArea}/messages/channels/${channelId}`
    );

    yield put({
      type: actions.SET_DELETE_CHANNEL_SUCCESS,
      removedChannel,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.success_delete_channel', {
          channelName: removedChannel.attributes.name,
        }),

        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_delete_channel'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setTransferTicketSaga({
  params,
}: SetTransferTicketActionSagaProps) {
  try {
    const { channelId, ticketId, targetChannelId } = params;
    const { dataArea } = yield select((state) => state.root);

    yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/tickets/${ticketId}/transfer`,
      {
        target_channel_id: targetChannelId,
      }
    );

    yield put({
      type: actions.SET_TRANSFER_TICKET_SUCCESS,
      channelId: targetChannelId,
      ticketId,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.success_transfer_ticket'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_transfer_ticket'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setDeleteMessageSaga({
  params,
}: SetDeleteMessageActionSagaProps) {
  const { chatId, channelId, messageId } = params;
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: message } = yield call(
      deleteApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages/${messageId}`
    );

    yield put({
      type: actions.SET_DELETE_MESSAGE_SUCCESS,
      message,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_delete_message'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setEditChannelSaga({ params }: SetEditChannelActionSagaProps) {
  try {
    const { form, channelId } = params;
    const { dataArea } = yield select((state) => state.root);

    const newAttendingHours = normalizeAttendingHoursRequest(
      form.attending_hours
    );

    yield call(patchApi, `/${dataArea}/messages/channels/${channelId}`, {
      ...form,
      attending_hours: newAttendingHours,
    });

    yield put(push(`/${dataArea}/messages`));

    yield put({
      type: actions.SET_EDIT_CHANNEL_SUCCESS,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.success_edit_channel', {
          channel: form.name,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_edit_channel'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setEditMessageSaga({ params }: SetEditMessageActionSagaProps) {
  try {
    const { chatId, channelId, channelType, messageId, message } = params;
    const { dataArea } = yield select((state) => state.root);

    const requestParams =
      channelType === 'ticket'
        ? {
            resource: 'messaging_domain/ticket',
            message: { message },
          }
        : { message: { message } };

    const { data, included } = yield call(
      patchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages/${messageId}`,
      requestParams
    );

    yield put({
      type: actions.SET_EDIT_MESSAGE_SUCCESS,
      message: data,
      included: included[0],
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_edit_message'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setStartOrCloseAttendanceSaga({
  params: { channelId, ticketId, action },
}: SetStartAttendanceSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: ticket, included } = yield call(
      postApi,
      `/${dataArea}/messages/channels/${channelId}/tickets/${ticketId}/${action}`
    );

    const inAttendanceTicket = normalizeStartOrCloseTicketParams(
      ticket,
      included
    );

    const successMessage = {
      start: i18n.t(
        'messages:omni_channel.request_toasts.start_ticket_attendance'
      ),
      close: i18n.t(
        'messages:omni_channel.request_toasts.close_ticket_attendance'
      ),
    };

    yield put({
      type: actions.SET_START_OR_CLOSE_ATTENDANCE_SUCCESS,
      inAttendanceTicket,
      toast: buildToast(
        successMessage[action],
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_ticket_attendance'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* socketConnectSaga() {
  try {
    const { realTime } = yield select((state) => state.root);

    const { socket: socketState } = yield select((state) => state.omniChannel);

    if (!realTime || socketState) return;

    const socket = io(
      process.env.WEBSOCKETS_SERVER_URL || 'ws://localhost:8080'
    );

    yield put({
      type: actions.SOCKET_CONNECT_SERVER_SUCCESS,
      socket,
    });

    yield all([fork(socketListenerSaga)]);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* socketEnterChatRoomSaga({ chatId }: SocketEnterChatRoomParams) {
  try {
    const { socket } = yield select((state) => state.omniChannel);

    if (!socket) return;

    yield socket.emit('join-room', chatId);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* socketLeaveChatRoomSaga({ chatId }: SocketLeaveChatRoomParams) {
  try {
    const { socket } = yield select((state) => state.omniChannel);

    if (!socket) return;

    yield socket.emit('leave-room', chatId);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChannelSaga({ channelId }: FetchChannelSagaProps) {
  try {
    const { data } = yield call(
      fetchApi,
      `/schools/messages/channels/${channelId}/edit.json`
    );

    const channel = normalizeCurrentChannel(data);

    yield put({
      type: actions.FETCH_CHANNEL_SUCCESS,
      channel,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_channel'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChannelsByStudentProfileSaga({
  studentProfileId,
}: FetchChannelsByStudentProfileSagaProps) {
  try {
    const { data: channels } = yield call(
      fetchApi,
      `/schools/messages/student_profiles/${studentProfileId}/channels`
    );

    yield put({
      type: actions.FETCH_CHANNELS_BY_STUDENT_PROFILE_SUCCESS,
      channels,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'messages:omni_channel.request_toasts.error_fetch_channel_by_student'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChatClassroomsSaga({
  channelId,
}: FetchChatClassroomsSagaProps) {
  try {
    const { history: chatClassrooms } = yield call(
      fetchApi,
      `/schools/messages/channels/${channelId}/classrooms`
    );

    yield put({
      type: actions.FETCH_CHAT_CLASSROOMS_SUCCESS,
      chatClassrooms,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_chat'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChatConfirmedResponsiblesSaga({
  studentId,
}: FetchChatConfirmedResponsiblesSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: confirmedResponsibles } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/${studentId}/confirmed_responsible_profiles`
    );

    yield put({
      type: actions.FETCH_CHAT_CONFIRMED_RESPONSIBLES_SUCCESS,
      confirmedResponsibles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_chat'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChatPendingCountSaga({
  params,
}: FetchChatPendingCountSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, filters } = params;

    const queryParams = !!filters && queryUrlFiltered(params);

    const { history: pendingCount } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/pending_count?${queryParams}`
    );

    yield put({
      type: actions.FETCH_CHAT_PENDING_COUNT_SUCCESS,
      pendingCount,
      channelId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchChatStudentProfilesSaga({
  classroomId,
}: FetchChatStudentProfilesSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: studentProfiles } = yield call(
      fetchApi,
      `/${dataArea}/classrooms/${classroomId}/student_profiles?with_responsibles=false`
    );

    yield put({
      type: actions.FETCH_CHAT_STUDENT_PROFILES_SUCCESS,
      studentProfiles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_chat'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChatsSaga({ params }: FetchChatsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, chatStatus, page } = params;

    const queryParams = queryUrlFiltered(params);

    const {
      data: chats,
      included,
      itemsCountPerPage,
      totalItemsCount,
    } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/list?chat_status=${chatStatus}&page=${page}${queryParams}`
    );

    yield put({
      type: actions.FETCH_CHATS_SUCCESS,
      chats,
      included,
      itemsCountPerPage,
      page,
      totalItemsCount,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_chats'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchChatSearchUsersSaga({
  params,
}: FetchChatSearchUsersSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      channelId,
      filters: { searchName },
      tabName,
      page,
    } = params;

    const {
      data: users,
      meta: { current_page, next_page },
    } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/${tabName}?name=${searchName}&page=${page}`
    );

    yield put({
      type: actions.FETCH_CHAT_SEARCH_USERS_SUCCESS,
      users,
      currentPage: current_page,
      nextPage: next_page,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_chat'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchApprovedMessageSaga({
  messageId,
}: FetchApprovedMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { activeChannel, activeChat } = yield select(
      (state: OmniChannelState) => state.omniChannel
    );

    if (!activeChannel || !activeChat || !messageId) return;

    const { data: message } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${activeChannel.id}/chats/${activeChat.id}/messages/${messageId}`
    );

    yield put({
      type: actions.FETCH_APPROVED_MESSAGE_SUCCESS,
      message,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchDeletedMessageSaga({
  messageId,
}: FetchDeletedMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { messages } = yield select(
      (state: OmniChannelState) => state.omniChannel
    );

    const { activeChannel, activeChat } = yield select(
      (state: OmniChannelState) => state.omniChannel
    );

    if (!activeChannel || !activeChat || !messageId) return;

    const deletedMessage = messages?.find(
      (message: Message) => message.id === messageId.toString()
    );

    if (!deletedMessage) return;

    const { data: message } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${activeChannel.id}/chats/${activeChat.id}/messages/${messageId}`
    );

    yield put({
      type: actions.FETCH_DELETED_MESSAGE_SUCCESS,
      message,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchEditMessageSaga({
  messageId,
  approvalStatus,
}: FetchEditMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { activeChannel, activeChat, messages } = yield select(
      (state: OmniChannelState) => state.omniChannel
    );

    const hasEditedMessage = messages.find(
      (message: Message) => message.id === messageId.toString()
    );

    if (
      !activeChannel ||
      !activeChat ||
      !messageId ||
      (!hasEditedMessage && approvalStatus === 'approved')
    )
      return;

    const { data: message, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${activeChannel.id}/chats/${activeChat.id}/messages/${messageId}`
    );

    yield put({
      type: actions.FETCH_EDIT_MESSAGE_SUCCESS,
      message,
      included,
      approvalStatus,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchMessageSaga({ messageId }: FetchMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { activeChannel, activeChat, isRedirectMessage } = yield select(
      (state: OmniChannelState) => state.omniChannel
    );

    if (!activeChannel || !activeChat || !messageId || isRedirectMessage)
      return;

    const { data: message, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${activeChannel.id}/chats/${activeChat.id}/messages/${messageId}`
    );

    if (message.attributes.type === 'sent') return;

    yield put({
      type: actions.FETCH_MESSAGE_SUCCESS,
      message,
      included,
    });
  } catch (error) {
    if (error?.response?.status === 403) return;
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_message'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchMessagesSaga({ params }: FetchMessagesSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, chatId, page } = params;

    const { data, itemsCountPerPage, totalItemsCount, included, meta } =
      yield call(
        fetchApi,
        `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages?page=${page}`
      );

    const messages = reverseArray(data);

    yield put({
      type: actions.FETCH_MESSAGES_SUCCESS,
      messages,
      meta,
      itemsCountPerPage,
      totalItemsCount,
      page,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchTicketPendingCountSaga({
  params,
}: FetchTicketPendingCountSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId } = params;

    const queryFilterParams = queryUrlFetchTicketsCount(params.filters);

    const { history: pendingCount } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/tickets/counters?${queryFilterParams}`
    );

    yield put({
      type: actions.FETCH_TICKET_PENDING_COUNT_SUCCESS,
      pendingCount,
      channelId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchTicketDetailSaga({
  params: { channelId, ticketId },
}: FetchTicketDetailSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: ticket, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/tickets/${ticketId}`
    );

    const currentTicket = normalizeTicketDetail(ticket, included);

    yield put({
      type: actions.FETCH_TICKET_DETAIL_SUCCESS,
      currentTicket,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchTicketsSaga({ params }: FetchTicketsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { channelId, page, requesterName, requesterType } = params;

    const queryFilterParams = queryUrlFetchTickets(params);

    if (requesterType && !requesterName) {
      yield put({
        type: actions.FETCH_TICKETS_SUCCESS,
        chatList: [],
      });
      return;
    }

    const {
      data: tickets,
      included,
      meta: { totalPages },
    } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/tickets?page[size]=25&page[number]=${page}${queryFilterParams}`
    );

    const chatList = normalizeTicketParams(tickets, included);

    yield put({
      type: actions.FETCH_TICKETS_SUCCESS,
      chatList,
      page,
      totalPages,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:omni_channel.request_toasts.error_fetch_tickets'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchUnapprovedMessageSaga({
  messageId,
}: FetchUnapprovedMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { activeChannel, activeChat } = yield select(
      (state: OmniChannelState) => state.omniChannel
    );

    if (!activeChannel || !activeChat || !messageId) return;

    const { data: message } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${activeChannel.id}/chats/${activeChat.id}/messages/${messageId}`
    );

    yield put({
      type: actions.FETCH_UNAPPROVED_MESSAGE_SUCCESS,
      message,
    });
  } catch (error) {
    if (error?.response?.status === 403) return;

    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchUnreadMessagesSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included, meta } = yield call(
      fetchApi,
      `/${dataArea}/messages/informations/unread_chats.json`
    );

    const unreadChats = normalizeCountUnreadParams(data, included);

    yield put({
      type: actions.FETCH_UNREAD_CHATS_SUCCESS,
      unreadChats,
      unreadCount: meta.unreadChatsTotal,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchCurrentChatSaga({
  channelId,
  chatId,
}: FetchCurrentChatSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: chat, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/channels/${channelId}/chats/${chatId}`
    );

    yield put({
      type: actions.FETCH_CURRENT_CHAT_SUCCESS,
      chat,
      included: included[0],
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchTicketStudentChannelsSaga({
  params,
}: FetchTicketStudentChannelsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { studentProfileId } = params;

    const { data: channels } = yield call(
      fetchApi,
      `/${dataArea}/messages/student_profiles/${studentProfileId}/channels/tickets`
    );

    yield put({
      type: actions.FETCH_TICKET_STUDENT_CHANNELS_SUCCESS,
      channels,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* omniChannelSagas() {
  yield all([takeLatest(actions.FETCH_CHANNELS_REQUEST, fetchChannelsSaga)]);
  yield all([takeLatest(actions.FETCH_CHANNEL_REQUEST, fetchChannelSaga)]);
  yield all([
    takeLatest(
      actions.FETCH_CHANNELS_BY_STUDENT_PROFILE_REQUEST,
      fetchChannelsByStudentProfileSaga
    ),
  ]);
  yield all([
    takeLatest(actions.FETCH_CHAT_CLASSROOMS_REQUEST, fetchChatClassroomsSaga),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_CHAT_CONFIRMED_RESPONSIBLES_REQUEST,
      fetchChatConfirmedResponsiblesSaga
    ),
  ]);
  yield all([
    takeLatest(actions.FETCH_EDIT_MESSAGE_REQUEST, fetchEditMessageSaga),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_CHAT_PENDING_COUNT_REQUEST,
      fetchChatPendingCountSaga
    ),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_CHAT_STUDENT_PROFILES_REQUEST,
      fetchChatStudentProfilesSaga
    ),
  ]);
  yield all([takeLatest(actions.FETCH_CHATS_REQUEST, fetchChatsSaga)]);
  yield all([
    takeLatest(actions.FETCH_CURRENT_CHAT_REQUEST, fetchCurrentChatSaga),
  ]);
  yield all([
    takeLatest(actions.FETCH_DELETED_MESSAGE_REQUEST, fetchDeletedMessageSaga),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_INITIAL_CHANNEL_FORM_REQUEST,
      fetchInitialChannelFormSaga
    ),
  ]);
  yield all([
    takeLatest(actions.FETCH_SCHOOL_USERS_REQUEST, fetchSchoolUsersSaga),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_CHAT_SEARCH_USERS_REQUEST,
      fetchChatSearchUsersSaga
    ),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_APPROVED_MESSAGE_REQUEST,
      fetchApprovedMessageSaga
    ),
  ]);
  yield all([takeLatest(actions.FETCH_MESSAGE_REQUEST, fetchMessageSaga)]);
  yield all([takeLatest(actions.FETCH_MESSAGES_REQUEST, fetchMessagesSaga)]);
  yield all([
    takeLatest(actions.FETCH_TICKET_DETAIL_REQUEST, fetchTicketDetailSaga),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_TICKET_PENDING_COUNT_REQUEST,
      fetchTicketPendingCountSaga
    ),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_TICKET_STUDENT_CHANNELS_REQUEST,
      fetchTicketStudentChannelsSaga
    ),
  ]);
  yield all([takeLatest(actions.FETCH_TICKETS_REQUEST, fetchTicketsSaga)]);
  yield all([
    takeLatest(
      actions.FETCH_UNAPPROVED_MESSAGE_REQUEST,
      fetchUnapprovedMessageSaga
    ),
  ]);
  yield all([
    takeLatest(actions.FETCH_UNREAD_CHATS_REQUEST, fetchUnreadMessagesSaga),
  ]);
  yield all([
    takeLatest(actions.SOCKET_ENTER_CHAT_ROOM_REQUEST, socketEnterChatRoomSaga),
  ]);
  yield all([
    takeLatest(actions.SOCKET_LEAVE_CHAT_ROOM_REQUEST, socketLeaveChatRoomSaga),
  ]);
  yield all([
    takeLatest(actions.SOCKET_CONNECT_SERVER_REQUEST, socketConnectSaga),
  ]);
  yield all([
    takeLatest(actions.SET_APPROVE_MESSAGE_REQUEST, setApproveMessageSaga),
  ]);
  yield all([
    takeLatest(actions.SET_CREATE_NEW_CHANNEL_REQUEST, setCreateNewChannelSaga),
  ]);
  yield all([
    takeLatest(actions.SET_CREATE_NEW_CHAT_REQUEST, setCreateNewChatSaga),
  ]);
  yield all([
    takeLatest(
      actions.SET_CREATE_NEW_CHAT_USER_REQUEST,
      setCreateNewChatUserSaga
    ),
  ]);
  yield all([
    takeLatest(actions.SET_CREATE_NEW_MESSAGE_REQUEST, setCreateNewMessageSaga),
  ]);
  yield all([
    takeLatest(
      actions.SET_CREATE_NEW_TICKET_BY_SCHOOL_REQUEST,
      setCreateNewTicketBySchoolSaga
    ),
  ]);
  yield all([
    takeLatest(actions.SET_DELETE_CHANNEL_REQUEST, setDeleteChannelSaga),
  ]);
  yield all([
    takeLatest(actions.SET_DELETE_MESSAGE_REQUEST, setDeleteMessageSaga),
  ]);
  yield all([takeLatest(actions.SET_EDIT_CHANNEL_REQUEST, setEditChannelSaga)]);
  yield all([takeLatest(actions.SET_EDIT_MESSAGE_REQUEST, setEditMessageSaga)]);
  yield all([
    takeLatest(
      actions.SET_MESSAGES_MARK_READ_STATUS_REQUEST,
      setMessagesMarkReadStatusSaga
    ),
  ]);
  yield all([
    takeLatest(actions.SET_REDIRECT_CHANNEL_PAGE, setRedirectChannelPageSaga),
  ]);
  yield all([
    takeLatest(
      actions.SET_REDIRECT_MESSAGE_REQUEST,
      setRedirectMessageRequestSaga
    ),
  ]);
  yield all([
    takeLatest(
      actions.SET_UPDATE_CHANNELS_ORDER_REQUEST,
      setUpdateChannelsOrderSagas
    ),
  ]);
  yield all([
    takeLatest(
      actions.SET_START_OR_CLOSE_ATTENDANCE_REQUEST,
      setStartOrCloseAttendanceSaga
    ),
  ]);
  yield all([
    takeLatest(actions.SET_TRANSFER_TICKET_REQUEST, setTransferTicketSaga),
  ]);
}

export default omniChannelSagas;
