import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const DetailsWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xl};
    align-items: start;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
    }
  `}
`;

export const CardDetailsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};
    align-items: center;
    justify-content: center;
  `}
`;

export const Card = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    background-color: ${colors.brand.primary.op10};
    border-radius: ${border.radius.md};

    svg {
      path {
        fill: ${colors.brand.primary.default};
      }
    }
  `}
`;

export const HistoryCreatorWrapper = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.xs};
    padding-top: ${space.sm};
    padding-bottom: ${space.sm};
    margin-top: ${space.xs};
    border-top: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;
