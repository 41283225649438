import React, { useContext } from 'react';
import { Box, Button, DefaultThemeProps } from '@agendaedu/ae-web-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { withRouter } from 'react-router';

import { StorageRoot } from 'store/storage/types';

import FormContainer from 'components/Form/FormContainer';
import RecipientsTab from 'components/Form/RecipientsTab';
import { StorageFileContext } from 'core/contexts/Storage/File';

const RecipientsStep = () => {
  const { t } = useTranslation('common');
  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const {
    storageForm: {
      current,
      isSubmitting,
      recipients: { formMeta },
    },
  } = useSelector((state: StorageRoot) => state.storage);

  const {
    form: { values, isValid, setFieldValue, submitForm },
    setCurrentStep,
  } = useContext(StorageFileContext);

  const footer = (
    <Box
      py="sm"
      position="fixed"
      display="flex"
      alignItems="center"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button variant="secondary" onClick={() => setCurrentStep(1)}>
        {t('common:button.back')}
      </Button>

      <Button
        testId="save-form"
        ml="auto"
        disabled={!isValid || isSubmitting}
        onClick={submitForm}
      >
        {t('common:button.save')}
      </Button>
    </Box>
  );

  const initialAttributes = {
    sent_kind: values.sentKind,
    persona_type: values.personaType,
    classroom_ids: values.classroomIds,
    classroom_with_student_profile_ids: values.classroomWithStudentProfileIds,
  };

  const isStudentsRecipients = current?.attributes.receiverType === 'student';

  const handleUpdates = (formContainerValues) => {
    const {
      sent_kind,
      persona_type,
      classroom_ids,
      classroom_with_student_profile_ids,
    } = formContainerValues;

    const shouldUpdateSentKind = !Object.is(values.sentKind, sent_kind);

    const shouldUpdatePersonaType = !Object.is(
      values.personaType,
      persona_type
    );

    const shouldUpdateClassroomIds = !Object.is(
      values.classroomIds,
      classroom_ids
    );

    const shouldUpdateClassroomWithStudentProfileIds = !Object.is(
      values.classroomWithStudentProfileIds,
      classroom_with_student_profile_ids
    );

    if (shouldUpdateSentKind) {
      setFieldValue('sentKind', sent_kind);
    }

    if (shouldUpdatePersonaType) {
      setFieldValue('personaType', persona_type);
    }

    if (shouldUpdateClassroomIds) {
      setFieldValue('classroomIds', classroom_ids);
    }

    if (shouldUpdateClassroomWithStudentProfileIds) {
      setFieldValue(
        'classroomWithStudentProfileIds',
        classroom_with_student_profile_ids
      );
    }
  };

  return (
    <Box mx="auto" pb="20vh">
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        position="relative"
        mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      >
        <Box maxWidth={breakpoints.tabletMD} px="auto" mt="lg">
          <FormContainer
            onUpdateAttribute={handleUpdates}
            initialAttributes={initialAttributes}
            formMeta={{
              ...formMeta,
              hideRadio: isStudentsRecipients,
              formStudentsProfiles: values.studentProfiles,
            }}
          >
            <RecipientsTab />
          </FormContainer>
        </Box>

        {footer}
      </Box>
    </Box>
  );
};

export default withRouter(RecipientsStep);
