import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, Text } from '@agendaedu/ae-web-components';

import StorageActions from 'store/storage/actions';
import { StorageRoot } from 'store/storage/types';

import Tabs from 'components/Messages/OmniChannel/Tabs';
import Breadcrumbs from '../StorageList/components/Breadcrumbs';
import DetailsTab from './components/DetailsTab';
import { DetailsSkeleton } from './skeleton';
import DeleteModal from '../Modals/DeleteModal';
import Toast from 'components/Toast';

import * as S from './styles';

import { Props } from './types';

const StorageDetails: React.FC<Props> = ({
  match: {
    params: { id: documentId },
  },
}): React.ReactElement => {
  const { t } = useTranslation(['storage']);
  const dispatch = useDispatch();

  const {
    fetchCurrentStorageRequest,
    setDeleteStorageRequest,
    toggleShowDeleteStorageModal,
    setSelectedStorage,
  } = StorageActions;

  const {
    common: {
      currentStorage,
      folderPath,
      selectedStorage,
      showDeleteStorageModal,
    },
  } = useSelector((state: StorageRoot) => state.storage);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`details.${key}`, params),
    [t]
  );

  const TABS = [
    {
      title: tBase('tab_title'),
      key: 0,
    },
  ];

  const handleDeleteStorage = useCallback(() => {
    dispatch(setDeleteStorageRequest(selectedStorage, true));
  }, [selectedStorage, dispatch, setDeleteStorageRequest]);

  const handleToggleDeleteModal = useCallback(() => {
    dispatch(toggleShowDeleteStorageModal());
    dispatch(setSelectedStorage(null));
  }, [dispatch, setSelectedStorage, toggleShowDeleteStorageModal]);

  useEffect(() => {
    documentId && dispatch(fetchCurrentStorageRequest(documentId));
  }, [dispatch, fetchCurrentStorageRequest, documentId]);

  return (
    <>
      {currentStorage ? (
        <Box display="flex" flexDirection="column" my="sm" mx="xl" gap="xs3">
          <Breadcrumbs
            link={`/${dataArea}/artifacts/folders/${folderPath?.prevFolder.id}`}
            title={folderPath?.prevFolder.title}
          />
          <Text
            variant="headline-h2-bold-24"
            fontWeight="bold"
            color="neutral.black"
            mb={0}
          >
            {tBase('title')}
          </Text>

          <S.DetailsTabWrapper>
            <S.TabsWrapper>
              <Box width={113}>
                <Tabs currentTab={0} tabs={TABS} />
              </Box>
            </S.TabsWrapper>

            <DetailsTab />
          </S.DetailsTabWrapper>
        </Box>
      ) : (
        <DetailsSkeleton />
      )}

      {selectedStorage && showDeleteStorageModal && (
        <DeleteModal
          onClick={handleDeleteStorage}
          onClose={handleToggleDeleteModal}
        />
      )}

      <Toast />
    </>
  );
};

export default withRouter(StorageDetails);
