import React from 'react';
import { DefaultThemeProps, Icon, Text } from '@agendaedu/ae-web-components';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import * as S from './styles';

import { Props } from './types';

const Breadcrumbs: React.FC<Props> = ({ title, link }): React.ReactElement => {
  const { colors } = useTheme() as DefaultThemeProps;

  return (
    <Link to={link}>
      <S.Wrapper>
        <div>
          <Icon
            name="chevron-left"
            size="sm"
            color={colors.brand.primary.default}
          />
        </div>

        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          color="brand.primary.default"
          variant="subtitle-medium-14"
          mb={0}
        >
          {title}
        </Text>
      </S.Wrapper>
    </Link>
  );
};

export default Breadcrumbs;
