import React, { useCallback, useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import {
  Box,
  Button,
  DefaultThemeProps,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { StorageFileContext } from 'core/contexts/Storage/File';

import { StorageRoot } from 'store/storage/types';

import FileUploader from '../FileUploader';
import FolderPath from 'pages/storage/Forms/components/FolderPath';
import { InfoStepSkeleton } from './skeleton';

const InfoStep = () => {
  const { t } = useTranslation(['storage', 'common']);
  const {
    currentStorageId,
    setCurrentStep,
    isInfoStepValid,
    form: { values, setFieldValue, errors },
  } = useContext(StorageFileContext);

  const { selectedFiles, setSelectedFiles } = useContext(UploadFilesContext);

  const { colors } = useTheme() as DefaultThemeProps;

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`file_form.info_step.${key}`, params),
    [t]
  );

  const formKind = currentStorageId ? 'edit' : 'new';

  const nextStep = () => setCurrentStep(2);

  const {
    storageForm: { current },
    common: { folderPath },
  } = useSelector((state: StorageRoot) => state.storage);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const folderPathData = {
    edit: {
      link: `/${dataArea}/artifacts/folders/${folderPath?.prevFolder?.id}`,
      folderTitle: folderPath?.prevFolder?.title,
      title: t('common.folder_path.edit_file'),
    },
    new: {
      link: `/${dataArea}/artifacts/folders/${current?.id}`,
      folderTitle: current?.attributes.title,
      title: t('common.folder_path.file'),
    },
  };

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isInfoStepValid}
        onClick={nextStep}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  useEffect(() => {
    setFieldValue('attachments', selectedFiles);
  }, [selectedFiles, setFieldValue]);

  useEffect(() => {
    if (formKind === 'edit' && current)
      setSelectedFiles(current?.attributes.attachments);
  }, [current, currentStorageId, formKind, setSelectedFiles]);

  if (!current && formKind === 'edit') return <InfoStepSkeleton />;
  return (
    <Box
      display="flex"
      position="relative"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
      mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
    >
      <Box width="100%" height="100%" mt="xl2" pb="md">
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={607}
          mx="auto"
          gap="md"
        >
          <Text variant="title-bold-20" color="neutral.black">
            {tBase('info_title')}
          </Text>

          <FolderPath
            title={folderPathData[formKind].title}
            link={folderPathData[formKind].link}
            folderTitle={folderPathData[formKind].folderTitle}
          />
          {formKind === 'edit' && (
            <TextField
              data-testid="title-input"
              id="title-field"
              fullWidth
              label={tBase('input_title_label')}
              placeholder={tBase('input_title_placeholder')}
              onChange={(e) => setFieldValue('title', e.target.value)}
              value={values.title}
              error={!!errors.title}
              errorMessage={errors.title}
            />
          )}

          <Text lineHeight="lg" variant="body-regular-14" color="neutral.gray1">
            {formKind === 'edit'
              ? tBase('edit_attachment_message')
              : tBase('attachments_type')}
          </Text>

          <FileUploader />
        </Box>
      </Box>
      {footer}
    </Box>
  );
};

export default InfoStep;
