/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import Label from 'components/Form/Label';
import { convertToBase64 } from 'core/utils/base64';
import withFormContext from 'core/hoc/withFormContext';
import { userIconStyle, cameraIconStyle } from './styles';
import * as S from './styles';

type FormContext = {
  updateAttribute: (attribute: string, value: File) => void;
};

interface AvatarUploaderProps {
  label?: string;
  attributeName: string | undefined;
  formContext: FormContext;
  avatar: string | null;
}

const AvatarUploader = ({
  label,
  attributeName,
  avatar,
  formContext: { updateAttribute },
}: AvatarUploaderProps) => {
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const haveAvatar = useCallback(() => {
    return avatar !== null;
  }, [avatar]);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!file) return;

    updateAttribute(attributeName, file);
    const { base64 } = await convertToBase64(file);
    setCurrentImage(base64);
  };

  useEffect(() => {
    if (haveAvatar()) setCurrentImage(avatar);
  }, [haveAvatar, avatar]);

  const renderThumbnail = () => (
    <S.AvatarThumbnail>
      <AgendaIcon name="user" size="x-large" style={userIconStyle} />
    </S.AvatarThumbnail>
  );

  const renderPreview = () => (
    <S.AvatarPreview>
      <img src={currentImage} />
    </S.AvatarPreview>
  );

  return (
    <div className="AvatarUploader">
      <Label>{label}</Label>

      <S.AvatarWrapper>
        {!currentImage ? renderThumbnail() : renderPreview()}

        <Button type="button">
          <AgendaIcon name="camera" size="small" style={cameraIconStyle} />
          <span className="file-input-btn">
            <input type="file" accept="image/*" onChange={onChange} />
          </span>
        </Button>
      </S.AvatarWrapper>
    </div>
  );
};

AvatarUploader.defaultProps = {
  label: '',
  avatar: null,
};

export default withFormContext(AvatarUploader);
