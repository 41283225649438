import React, { useCallback, useContext } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  DefaultThemeProps,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import { StorageFolderContext } from 'core/contexts/Storage/Folder';

import { StorageRoot } from 'store/storage/types';

import FolderPath from 'pages/storage/Forms/components/FolderPath';
import { InfoStepSkeleton } from './skeleton';

import { Props } from './types';

const InfoStep: React.FC<Props> = ({ rootFolder }): React.ReactElement => {
  const { t } = useTranslation(['storage', 'common']);
  const {
    currentStorageId,
    setCurrentStep,
    isInfoStepValid,
    form: { values, setFieldValue, errors },
  } = useContext(StorageFolderContext);

  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`folder_form.info_step.${key}`, params),
    [t]
  );

  const formKind = currentStorageId ? 'edit' : 'new';

  const nextStep = () => setCurrentStep(2);

  const {
    storageForm: { current },
    common: { folderPath },
  } = useSelector((state: StorageRoot) => state.storage);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const folderPathData = {
    edit: {
      link: !folderPath?.prevFolder?.id
        ? `/${dataArea}/artifacts`
        : `/${dataArea}/artifacts/folders/${folderPath?.prevFolder?.id}`,
      folderTitle: folderPath?.prevFolder?.title,
      title: t('common.folder_path.edit_document'),
    },
    new: {
      link: `/${dataArea}/artifacts/folders/${current?.id}`,
      folderTitle: current?.attributes.title,
      title: t('common.folder_path.document'),
    },
  };

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isInfoStepValid}
        onClick={nextStep}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  if (formKind === 'edit' && !current) return <InfoStepSkeleton />;

  return (
    <Box
      display="flex"
      position="relative"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
      mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
    >
      <Box width="100%" height="100%" mt="xl2" pb="md" overflow="auto">
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={breakpoints.tabletMD}
          mx="auto"
          gap="md"
        >
          <Text variant="title-bold-20" color="neutral.black">
            {tBase('title')}
          </Text>

          {!rootFolder && (
            <FolderPath
              title={folderPathData[formKind].title}
              link={folderPathData[formKind].link}
              folderTitle={folderPathData[formKind].folderTitle}
            />
          )}

          <TextField
            id="title-field"
            fullWidth
            label={tBase('title_field_label')}
            placeholder={tBase('title_field_placeholder')}
            onChange={(e) => setFieldValue('title', e.target.value)}
            value={values.title}
            error={!!errors.title}
            errorMessage={errors.title}
          />

          <TextField
            id="description-field"
            fullWidth
            label={tBase('description_field_label')}
            onChange={(e) => setFieldValue('description', e.target.value)}
            multiline
            rows={4}
            value={values.description}
            error={!!errors.description}
            errorMessage={errors.description}
          />

          <Text variant="body-regular-12" color="neutral.gray1">
            {tBase('required_fields')}
          </Text>
        </Box>
      </Box>

      {footer}
    </Box>
  );
};

export default InfoStep;
