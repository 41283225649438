import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import {
  DefaultThemeProps,
  Text,
  theme,
  ImageUploader,
} from '@agendaedu/ae-web-components';

import FormFieldset from 'components/Form/Fieldset';
import WalletConfiguration from 'components/Payments/Shop/FormSections/WalletConfiguration';
import Field from 'components/Form/Field';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import LabelWithTooltip from 'components/Payments/Shop/OfferForm/LabelWithTooltip';
import { NewOfferFormInformationTabProps } from 'components/Payments/Shop/OfferForm/types';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import validations from './validations';

import * as S from './styles';

const OfferFormInformationTab = ({
  formContext: {
    form: {
      max_quantity_per_order,
      size,
      status,
      has_stock,
      quantity_stock,
      image,
    },
    updateAttribute,
  },
}: NewOfferFormInformationTabProps) => {
  const {
    policies: { can_display_options },
  } = useSelector((state: RootStateOrAny) => state.root);
  const { t } = useTranslation(['payments']);

  const defaultTheme: DefaultThemeProps = theme;
  const { colors } = defaultTheme;

  const toggleSwitchStock = () => {
    updateAttribute('has_stock', !has_stock);
  };

  const handleImageChange = (image: File) => {
    updateAttribute('image', image);
  };

  return (
    <div>
      <FormFieldset>
        <WalletConfiguration />
        <div className="row">
          <Field
            label={t('marketplace.new_offer_form.name_field')}
            placeholder={t('marketplace.new_offer_form.name_placeholder_field')}
            attributeName="title"
          />
        </div>
        <S.ImageWrapper>
          <LabelWithTooltip
            label={t('marketplace.new_offer_form.image_field')}
            tooltipText={t('marketplace.new_offer_form.image_tooltip')}
          />

          <S.ImageUploaderWrapper value={image} onChange={handleImageChange} />
        </S.ImageWrapper>
        <div className="row">
          <Field
            type="textArea"
            label={t('marketplace.new_offer_form.description_field')}
            placeholder=""
            attributeName="description"
          />
        </div>
        <S.ItemsWrapper>
          <S.ItemsField
            type="number"
            size="small"
            value={max_quantity_per_order}
            label={
              <LabelWithTooltip
                label={t('marketplace.new_offer_form.limit_per_buy_field')}
                tooltipText={t(
                  'marketplace.new_offer_form.limit_per_buy_tooltip'
                )}
              />
            }
            attributeName="max_quantity_per_order"
          />

          {can_display_options && (
            <S.ItemsField
              type="text"
              size="small"
              label={t('marketplace.new_offer_form.size_field')}
              value={size}
              attributeName="size"
              disabled={status === 'active'}
              placeholder={
                status === 'active'
                  ? ''
                  : t('marketplace.new_offer_form.size_placeholder_field')
              }
            />
          )}
        </S.ItemsWrapper>

        <Text color={colors.neutral.gray1} variant="label-regular-16" mb={0}>
          {t('marketplace.new_offer_form.stock_title')}
        </Text>

        <S.StockWrapper>
          <S.StockSwitchWrapper>
            <ToggleSwitch
              testId="toggleStock"
              toggled={has_stock}
              onChange={toggleSwitchStock}
            />

            <Text
              color={colors.neutral.gray2}
              variant="body-regular-14"
              lineHeight="lg"
              mb={0}
            >
              {t('marketplace.new_offer_form.stock_toggle_text')}
            </Text>
          </S.StockSwitchWrapper>

          {has_stock && (
            <S.StockField
              type="number"
              value={quantity_stock}
              label={
                <LabelWithTooltip
                  label={t('marketplace.new_offer_form.stock_field')}
                  tooltipText={parse(
                    t('marketplace.new_offer_form.stock_tooltip_field')
                  )}
                />
              }
              attributeName="quantity_stock"
              placeholder={t(
                'marketplace.new_offer_form.stock_placeholder_field'
              )}
            />
          )}
        </S.StockWrapper>

        <S.Paragraph>
          {t('marketplace.new_offer_form.required_fields')}
        </S.Paragraph>
      </FormFieldset>
    </div>
  );
};

export default tabifyForWizard({
  title: '1. Item',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(OfferFormInformationTab));
