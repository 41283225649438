import React, { useEffect, useRef } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import FormFieldset from 'components/Form/Fieldset';
import BillConfiguration from 'components/Payments/PaymentsContainer/FormSections/BillConfiguration';
import GeneralInformations from 'components/Payments/PaymentsContainer/FormSections/GeneralInformations';
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';

import validations from './validations.js';

const EnrollmentPlanFormInformationsTab = ({
  formContext: {
    changeMeta,
    form: { price },
  },
}) => {
  const informationTabRef = useRef(null);

  useEffect(() => {
    informationTabRef.current.scrollIntoView(true);
    changeMeta('nextStepDisabled', false);
  }, []);

  return (
    <div className="EnrollmentPlanFormInformationsTab" ref={informationTabRef}>
      <FormFieldset>
        <WalletConfiguration />
        <GeneralInformations onlyDescription />
        <BillConfiguration isEnrollment />
        <EdupayDiscount
          validations={validations}
          withoutTitle
          showCreditCardDiscount
          isEnrollment
        />
        <SimpleSummary showTitle price={price} />
      </FormFieldset>
    </div>
  );
};

EnrollmentPlanFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '2. Cobranças',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(EnrollmentPlanFormInformationsTab));
