import {
  Box,
  RangeDate,
  Select,
  TextField,
} from '@agendaedu/ae-web-components';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import * as S from './styles';

import { formatRangeDate } from 'core/utils/date';

import StorageActions from 'store/storage/actions';
import { StorageRoot } from 'store/storage/types';

import { SideFilterModal } from 'pages/storage/Modals/SideFilterModal';

import { Props } from './types';

const Filters: React.FC<Props> = ({ folderAscendingId }): JSX.Element => {
  const { t } = useTranslation('storage');
  const dispatch = useDispatch();

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`storage_list.filters.${key}`, params),
    [t]
  );

  const { setChangeFilters } = StorageActions;

  const {
    storageList: { filters },
  } = useSelector((state: StorageRoot) => state.storage);

  const [startRangeDate, setStartRangeDate] = useState<Moment>(null);
  const [endRangeDate, setEndRangeDate] = useState<Moment>(null);
  const [title, setTitle] = useState<string>(null);

  const handleRangeDateChange = ({ startDate, endDate }) => {
    setStartRangeDate(startDate);
    setEndRangeDate(endDate);
  };

  const ORDER_FILTER_OPTIONS = [
    { label: tBase('ordernation_desc'), value: 'desc' },
    { label: tBase('ordernation_asc'), value: 'asc' },
    { label: tBase('ordernation_alphabetic'), value: 'alphabetic' },
  ];

  const debouncedFetchStorages = _.debounce((value: string) => {
    dispatch(setChangeFilters({ ...filters, title: value }));
  }, 500);

  const handleChangeFilterOrdenation = ({ value }): void => {
    dispatch(setChangeFilters({ ...filters, ordenation: value }));
  };

  const handleTitleChange = ({ target }) => {
    setTitle(target.value);
    debouncedFetchStorages(target.value);
  };

  useEffect(() => {
    if (Boolean(startRangeDate) === Boolean(endRangeDate)) {
      dispatch(
        setChangeFilters({
          ...filters,
          startDate: formatRangeDate(startRangeDate),
          endDate: formatRangeDate(endRangeDate),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endRangeDate, startRangeDate]);

  return (
    <Box display="flex" flexDirection="row" py="xs">
      <S.FiltersWrapper hasSideFilter={!folderAscendingId}>
        <TextField
          clearable
          value={title}
          icon="search"
          placeholder={tBase('search_placeholder')}
          onChange={handleTitleChange}
          fullWidth
          data-testid="title-filter"
        />
        <Select
          value={filters?.ordenation}
          options={ORDER_FILTER_OPTIONS}
          onChange={handleChangeFilterOrdenation}
          fullWidth
        />
        <Box display="flex" flexDirection="row" width="100%">
          <RangeDate
            value={{
              startDate: startRangeDate,
              endDate: endRangeDate,
            }}
            id="date-range"
            data-testid="date-filter"
            handleChange={handleRangeDateChange}
            handleOutsideRange={(day: Moment) => day.isAfter(moment(), 'day')}
          />

          <S.SideFilterWrapper>
            {!folderAscendingId && <SideFilterModal />}
          </S.SideFilterWrapper>
        </Box>
      </S.FiltersWrapper>
    </Box>
  );
};

export default Filters;
