import styled from 'styled-components';

const centerFlex = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  width: 146px;
  height: 146px;
  margin-top: 8px;

  > .Button {
    ${centerFlex}
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    .file-input-btn {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .file-input-btn input[type='file'] {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const AvatarThumbnail = styled.div`
  ${centerFlex}
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 50%;
`;

export const AvatarPreview = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;
  }
`;

export const userIconStyle = {
  fontSize: '65px',
  color: '#999999',
};

export const cameraIconStyle = {
  fontSize: '25px',
};
