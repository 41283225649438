import React, { useCallback, useContext } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  DefaultThemeProps,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';

import { StorageDocumentContext } from 'core/contexts/Storage/Document';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

import { StorageRoot } from 'store/storage/types';

import FormContainer from 'components/Form/FormContainer';
import FileField from 'components/Form/FileField';

import FolderPath from 'pages/storage/Forms/components/FolderPath';

import * as S from './styles';

import { InfoStepSkeleton } from './skeleton';

const InfoStep = (): React.ReactElement => {
  const { t } = useTranslation(['storage', 'common']);

  const {
    googleClientId,
    currentStorageId,
    setCurrentStep,
    isInfoStepValid,
    form: { values, setFieldValue, errors },
  } = useContext(StorageDocumentContext);

  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const {
    storageForm: { current },
    common: { folderPath },
  } = useSelector((state: StorageRoot) => state.storage);

  const formKind = currentStorageId ? 'edit' : 'new';

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`document_form.info_step.${key}`, params),
    [t]
  );

  const nextStep = () => setCurrentStep(2);

  const handleChangeFormAttributes = ({ directAttachment }) => {
    if (!directAttachment?.length && !values.attachments) return;
    setFieldValue('attachments', directAttachment);
  };

  const folderPathData = {
    edit: {
      link: `/${dataArea}/artifacts/folders/${folderPath?.prevFolder?.id}`,
      folderTitle: folderPath?.prevFolder?.title,
      title: t('common.folder_path.edit_document'),
    },
    new: {
      link: `/${dataArea}/artifacts/folders/${current?.id}`,
      folderTitle: current?.attributes.title,
      title: t('common.folder_path.document'),
    },
  };

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isInfoStepValid}
        onClick={nextStep}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  const initialAttributes = {
    directAttachment: values.attachments ?? [],
  };

  if (!values.attachments && formKind === 'edit') return <InfoStepSkeleton />;

  return (
    <Box
      display="flex"
      position="relative"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
      mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
    >
      <Box width="100%" height="100%" mt="xl2" pb="md">
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={breakpoints.tabletMD}
          mx="auto"
          mb="10vh"
          gap="md"
        >
          <Text variant="title-bold-20" color="neutral.black">
            {tBase('title')}
          </Text>

          <FolderPath
            title={folderPathData[formKind].title}
            link={folderPathData[formKind].link}
            folderTitle={folderPathData[formKind].folderTitle}
          />

          <TextField
            id="title-field"
            fullWidth
            label={tBase('title_field_label')}
            placeholder={tBase('title_field_placeholder')}
            onChange={(e) => setFieldValue('title', e.target.value)}
            value={values.title}
            error={!!errors.title}
            errorMessage={errors.title}
          />

          <FormContainer
            initialAttributes={initialAttributes}
            onUpdateAttribute={handleChangeFormAttributes}
          >
            <Text
              variant="label-regular-14"
              mb={12}
              lineHeight="lg"
              color="neutral.gray1"
            >
              {tBase('description_field_label')}
            </Text>
            <S.TextAreaField
              quillProps={{
                onChange: (value: string) =>
                  values.description !== value &&
                  setFieldValue(
                    'description',
                    validateTextAreaPresence(value) ? value : ''
                  ),
                value: values.description,
              }}
              type="textArea"
              useImage
              error={!!errors.description}
            />

            {errors.description && (
              <Text
                variant="label-regular-14"
                lineHeight="lg"
                color="context.alert.default"
                mt={-10}
              >
                {errors.description}
              </Text>
            )}

            <S.FileFieldWrapper>
              <FileField
                attributeName="directAttachment"
                googleClientId={googleClientId}
                useGoogleDrive
              />
            </S.FileFieldWrapper>
          </FormContainer>

          <Text mt={-12} variant="body-regular-12">
            {tBase('required_fields')}
          </Text>
        </Box>
      </Box>

      {footer}
    </Box>
  );
};

export default InfoStep;
