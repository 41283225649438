import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const DriveWrapper = styled.div`
  display: flex;
  width: 100%;
  .google-picker {
    width: 100%;
  }
`;

export const ButtonImage = styled.img`
  width: 18px;
`;

export const FileUploderButton = styled.button`
  ${({ theme: { colors, border, typography, space } }: ThemeProps) => css`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${space.xs};
    background-color: ${colors.neutral.white};
    border-radius: ${border.radius.md};
    border: ${border.style.solid} ${border.width.sm}
      ${colors.brand.primary.default};
    transition: background-color 0.25s;

    ${typography.button.Bold16};
    color: ${colors.brand.primary.default};

    :hover {
      background-color: ${colors.brand.primary.op10};
      border: 1px ${border.style.solid} ${colors.brand.primary.op10};
    }

    &:active {
      background-color: ${colors.brand.primary.op20};
      border: 1px ${border.style.solid} ${colors.brand.primary.op20};
    }
  `}
`;
