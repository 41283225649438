import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, FullScreenModal, Stepper } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import StorageActions from 'store/storage/actions';
import { StorageRoot } from 'store/storage/types';

import withAppContext from 'core/hoc/withAppContext';
import useWindowDimensions from 'core/hooks/useWindowDimensions';
import { isMobile } from 'core/utils/viewport';
import { StorageDocumentContext } from 'core/contexts/Storage/Document';

import InfoStep from './components/InfoStep';
import Toast from 'components/Toast';
import RecipientsStep from './components/RecipientsStep';
import DiscardModal from 'pages/storage/Modals/DiscardModal';
import NotificationModal from 'pages/storage/Modals/NotificationModal';

import { PersonalType, Props } from './types';

const DocumentForm = ({ match, history }: Props) => {
  const {
    storageForm: { current, form: formValues },
  } = useSelector((state: StorageRoot) => state.storage);

  const {
    form,
    currentStorageId,
    currentStep,
    setCurrentStep,
    isInfoStepValid,
  } = useContext(StorageDocumentContext);

  const folderAscendingId =
    match.params.id || current?.attributes.parentFolderId;

  const kind = currentStorageId ? 'edit' : 'new';

  const currentParentFolderId = {
    new: match.params.id,
    edit: current?.attributes.parentFolderId,
  };

  const {
    fetchRecipientsClassrooms,
    fetchCurrentStorageFormRequest,
    toggleShowDiscardModal,
    toggleShowNotificationModal,
    saveEditDocumentRequest,
    saveDocumentRequest,
  } = StorageActions;

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const { t } = useTranslation(['storage', 'common']);
  const dispatch = useDispatch();

  const { screenWidth } = useWindowDimensions();

  const [isMobileScreen, setIsMobile] = useState(isMobile(screenWidth));

  const tBase = useCallback((key: string) => t(`document_form.${key}`), [t]);

  const handleGoBackForm = useCallback((): void => {
    dispatch(toggleShowDiscardModal());
    history.push(
      `/${dataArea}/artifacts/folders/${
        currentStorageId ? current?.attributes.parentFolderId : current?.id
      }`
    );
  }, [
    current,
    currentStorageId,
    dataArea,
    dispatch,
    history,
    toggleShowDiscardModal,
  ]);

  const handleShowDiscardModal = useCallback((): void => {
    dispatch(toggleShowDiscardModal());
  }, [dispatch, toggleShowDiscardModal]);

  const handleShowNotificationModal = useCallback((): void => {
    dispatch(toggleShowNotificationModal());
  }, [dispatch, toggleShowNotificationModal]);

  const handleSubmitForm = ({ notification: notify }): void => {
    handleShowNotificationModal();

    const values = {
      ...formValues,
      notify,
    };

    if (currentStorageId) {
      dispatch(
        saveEditDocumentRequest({
          ...values,
          currentStorageId,
        })
      );
      return;
    }
    dispatch(
      saveDocumentRequest({
        ...values,
        folderAscendingId,
      })
    );
  };

  const stepInfos = useMemo(
    () => [
      {
        step: 1,
        title: tBase('info_step.title'),
        isActive: currentStep === 1,
        isDisabled: false,
      },
      {
        title: tBase('recipients_step.title'),
        isActive: currentStep === 2,
        isDisabled: !isInfoStepValid,
        step: 2,
      },
    ],
    [currentStep, tBase]
  );

  useEffect(() => {
    dispatch(fetchRecipientsClassrooms(currentParentFolderId[kind]));
  }, [dispatch, fetchCurrentStorageFormRequest, fetchRecipientsClassrooms]);

  useEffect(() => {
    if (currentStorageId) {
      dispatch(fetchCurrentStorageFormRequest(currentStorageId, kind));
      return;
    }
    folderAscendingId &&
      dispatch(fetchCurrentStorageFormRequest(folderAscendingId, kind));
  }, [
    currentStorageId,
    dispatch,
    fetchCurrentStorageFormRequest,
    folderAscendingId,
    kind,
  ]);

  useEffect(() => {
    if (currentStorageId && current) {
      const personalType: PersonalType = {
        responsible: 'responsibles',
        student: 'students',
        family: 'both',
      };

      form.setValues({
        title: current.attributes.title,
        description: current.attributes.description,
        attachments: current.attributes.attachments,
        sentKind:
          current.attributes.receiverType === 'classroom'
            ? 'classrooms'
            : 'students',
        personaType: personalType[current.attributes.availableTo],
        classroomIds: current.attributes.classrooms.map(
          (classroom) => classroom.id
        ),
        classroomWithStudentProfileIds:
          current.attributes.classroomWithStudentProfileIds,
      });
    }
  }, [current, currentStorageId]);

  useEffect(() => {
    if (current?.attributes.receiverType === 'student') {
      form.setFieldValue('sentKind', 'students');
      form.setFieldValue(
        'studentProfiles',
        current?.attributes.studentProfiles
      );
    } else {
      form.setFieldValue('sentKind', 'classrooms');
    }
  }, [current?.attributes.receiverType]);

  const getHeaderContent = useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        width="min-content"
        mx="auto"
        gap="sm"
      >
        {stepInfos.map((item, index) => (
          <Stepper
            key={index}
            isMobile={isMobileScreen}
            onClick={() => setCurrentStep(item.step)}
            {...item}
          />
        ))}
      </Box>
    ),
    [isMobileScreen, stepInfos]
  );

  const getStepContent = {
    1: <InfoStep />,
    2: <RecipientsStep />,
  }[currentStep];

  useEffect(() => {
    if (isMobileScreen !== isMobile(screenWidth)) {
      setIsMobile(isMobile(screenWidth));
    }
  }, [isMobileScreen, screenWidth]);

  return (
    <>
      <FullScreenModal
        isOpen
        title={kind === 'new' ? tBase('title') : tBase('title_edit')}
        headerContent={getHeaderContent}
        onClose={handleShowDiscardModal}
      >
        <Box
          data-testid="content"
          display="flex"
          flexDirection="column"
          flex={1}
          overflow="auto"
          mb={72}
        >
          {getStepContent}
        </Box>
      </FullScreenModal>

      <Toast />

      <DiscardModal
        onClose={handleShowDiscardModal}
        onClick={handleGoBackForm}
        kind="document"
      />

      <NotificationModal
        onClose={handleShowNotificationModal}
        onClick={handleSubmitForm}
        kind="document"
        event={kind}
      />
    </>
  );
};

export default withAppContext(withRouter(DocumentForm));
