import { Box } from '@agendaedu/ae-web-components';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import StorageActions from 'store/storage/actions';
import actionFilters from 'store/filters/actions';
import { initialStorageFilters } from 'store/storage';
import { StorageRoot } from 'store/storage/types';
import { FilterStatesProps } from 'store/filters/types';

import DeleteModal from '../Modals/DeleteModal';
import DetailsModal from '../Modals/DetailsModal';
import Toast from 'components/Toast';
import Filters from './components/Filters';
import Header from './components/Header';
import List from './components/List';

import { Props } from './types';

const StorageList = ({
  match: {
    params: { id: folderAscendingId },
  },
}: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const isRootFolder = !folderAscendingId;

  const {
    toggleShowDeleteStorageModal,
    setSelectedStorage,
    setDeleteStorageRequest,
    fetchStoragesRequest,
    fetchCurrentStorageRequest,
    setChangeFilters,
  } = StorageActions;

  const { fetchHeadquartersRequest, fetchActiveSchoolTermsRequest } =
    actionFilters;

  const {
    common: { selectedStorage, showDeleteStorageModal },
    storageList: { showDetailsModal, filters },
  } = useSelector((state: StorageRoot) => state.storage);

  const { headquarters } = useSelector(
    (state: FilterStatesProps) => state.filters
  );

  const handleToggleDeleteModal = useCallback(() => {
    dispatch(toggleShowDeleteStorageModal());
    dispatch(setSelectedStorage(null));
  }, [dispatch, setSelectedStorage, toggleShowDeleteStorageModal]);

  const handleDeleteStorage = useCallback(() => {
    const redirectRootPage = folderAscendingId === selectedStorage.id;
    dispatch(setDeleteStorageRequest(selectedStorage, redirectRootPage));
  }, [folderAscendingId, selectedStorage, dispatch, setDeleteStorageRequest]);

  useEffect(() => {
    if (!isRootFolder) {
      dispatch(fetchCurrentStorageRequest(folderAscendingId));
      dispatch(setChangeFilters(initialStorageFilters));
    }
  }, [
    dispatch,
    fetchCurrentStorageRequest,
    folderAscendingId,
    isRootFolder,
    setChangeFilters,
  ]);

  useEffect(() => {
    dispatch(fetchStoragesRequest(1, folderAscendingId));
  }, [
    dispatch,
    fetchStoragesRequest,
    folderAscendingId,
    filters?.title,
    filters?.ordenation,
    filters?.startDate,
    filters?.endDate,
    filters?.activeSchoolTermId,
    filters?.classroomId,
    filters?.educationalStageId,
    filters?.headquarterId,
  ]);

  useEffect(() => {
    if (!headquarters) {
      dispatch(fetchHeadquartersRequest());
      dispatch(fetchActiveSchoolTermsRequest());
    }
  }, [
    dispatch,
    fetchActiveSchoolTermsRequest,
    fetchHeadquartersRequest,
    headquarters,
  ]);

  return (
    <>
      <Box
        data-testid="storage-wrapper"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        mt="sm"
        mb="-78px"
        gap="xs"
        mx={{ _: 'sm', desktopLG: 'xl' }}
      >
        <Header folderAscendingId={folderAscendingId} />
        <Filters folderAscendingId={folderAscendingId} />
        <List folderAscendingId={folderAscendingId} />
      </Box>

      <Toast />

      {selectedStorage && showDeleteStorageModal && (
        <DeleteModal
          onClose={handleToggleDeleteModal}
          onClick={handleDeleteStorage}
        />
      )}

      {selectedStorage && showDetailsModal && <DetailsModal />}
    </>
  );
};

export default withRouter(StorageList);
