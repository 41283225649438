import {
  Box,
  DefaultThemeProps,
  Modal,
  Text,
} from '@agendaedu/ae-web-components';
import React, { useContext } from 'react';
import { Props } from './types';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UPLOAD_IMAGES_KIND } from 'core/constants';
import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { validFilesTypes } from 'core/constants/albums';

const KindUploadModal = ({ isOpen, onClose }: Props) => {
  const { colors, border } = useTheme() as DefaultThemeProps;
  const { t } = useTranslation('albums');
  const { googleDriveWrapper, openSelectLocalFiles } =
    useContext(UploadFilesContext);

  const ContentBox = ({
    id,
    type,
    title,
    imgUrl,
  }: {
    id: string;
    type: 'local' | 'google_drive';
    title: string;
    imgUrl: string;
  }) => {
    const onClick = () => {
      if (type === 'local') {
        openSelectLocalFiles(validFilesTypes);
      }
      onClose();
    };

    return (
      <Box
        id={id}
        p="lg"
        mx="xs2"
        mt="sm"
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderWidth={border.width.sm}
        borderColor={colors.neutral.gray4}
        borderStyle={border.style.solid}
        borderRadius={border.radius.md}
        maxWidth={{ mobileXS: '100%', desktopLG: 200 }}
        overflow="hidden"
        cursor="pointer"
        onClick={onClick}
      >
        <>
          <img src={imgUrl} alt={title} width="30%" />

          <Text
            variant="title-bold-16"
            textAlign="center"
            fontWeight="bold"
            mt="md"
            color="neutral.black"
          >
            {title}
          </Text>
        </>
      </Box>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('album_details.kind_upload_modal.title')}
    >
      <Box display={{ mobileXS: 'block', desktopLG: 'flex' }}>
        <ContentBox
          id="upload-local-action"
          type="local"
          title={t('album_details.kind_upload_modal.local')}
          imgUrl={UPLOAD_IMAGES_KIND.local}
        />

        {googleDriveWrapper(
          <ContentBox
            id="upload-google-drive-action"
            type="google_drive"
            title={t('album_details.kind_upload_modal.google_drive')}
            imgUrl={UPLOAD_IMAGES_KIND.googleDrive}
          />
        )}
      </Box>
    </Modal>
  );
};

export default KindUploadModal;
