import axios from 'axios';
import { extractBody } from 'core/utils/request';

export default class ClassroomsService {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  async getStudents(classroomId, withResponsibles = false) {
    try {
      const response = await axios.get(
        `/${this.dataArea}/classrooms/${classroomId}/student_profiles.json`,
        { params: { with_responsibles: withResponsibles } }
      );

      const students = extractBody(response).data;
      return students;
    } catch (error) {
      throw error;
    }
  }

  async getStudentsNotProgressed(classroomId) {
    try {
      const response = await axios.get(
        `/${this.dataArea}/classrooms/${classroomId}/student_profiles/students_not_progressed.json`
      );
      const students = extractBody(response).data;
      return students;
    } catch (error) {
      throw error;
    }
  }

  async getClassrooms() {
    try {
      const response = await axios.get(`/schools/messages/classrooms.json`);
      const classrooms = extractBody(response);
      return classrooms;
    } catch (error) {
      throw error;
    }
  }

  async getChannelClassrooms(ChannelId) {
    try {
      const response = await axios.get(
        `/schools/messages/channels/${ChannelId}/classrooms.json`
      );
      const classrooms = extractBody(response);
      return classrooms;
    } catch (error) {
      throw error;
    }
  }

  async getClassroomsFromLastSchoolTermActive() {
    try {
      const response = await axios.get(
        `/schools/classrooms/list_classrooms_from_last_school_term_active.json`
      );
      const classrooms = extractBody(response);
      return classrooms;
    } catch (error) {
      throw error;
    }
  }
}
