import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';
import React from 'react';
import { Link } from 'react-router-dom';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

import { Props } from './types';

const FolderPath: React.FC<Props> = ({
  link,
  folderTitle,
  title,
}): React.ReactElement => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors,
    border: { radius },
  } = defaultTheme;

  return (
    <S.AlertWrapper variant="informative">
      <Box display="flex" flexDirection="row">
        {!folderTitle ? (
          <Skeleton
            data-testid="skeleton"
            width={240}
            height={20}
            borderRadius={radius.md}
            primaryColor={colors.context.info.op20}
            secondaryColor={colors.context.info.op10}
          />
        ) : (
          <>
            {title}
            <Link to={link}>
              <S.LinkTitle
                mb={0}
                color="context.info.default"
                variant="subtitle-medium-14"
              >
                {folderTitle}
              </S.LinkTitle>
            </Link>
          </>
        )}
      </Box>
    </S.AlertWrapper>
  );
};

export default FolderPath;
