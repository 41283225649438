import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-left: -4px;
    }

    p {
      :hover {
        color: ${colors.brand.primary.dark};

        svg {
          fill: ${colors.brand.primary.dark};
        }
      }
    }
  `}
`;
