import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import shopAction from 'store/edupay/shop/actions';
import {
  ALLOWED_PAYMENT_METHOD,
  NEW_OFFER_MAX_INSTALLMENTS,
} from 'core/constants';
import { INSTALLMENTS_SCHOOLPRODUCT } from 'core/constants/index';
import { ShopStoreProps } from 'store/edupay/shop/types';
import normalizeParams from './normalizeParams';
import {
  FormMeta,
  InformationTabForm,
  OfferTabForm,
  RecipientsTabForm,
} from 'components/Payments/Shop/OfferForm/types';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import OfferForm from 'components/Payments/Shop/OfferForm';
import AlertModal from 'components/AlertModal';

const TEXT_CONFIRMATION_MODAL =
  'Ao confirmar, a oferta ficará disponível no aplicativo e disponível para compra. Tem certeza que deseja criar?';

const NewOffer = () => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [latestOffer, setLatestOffer] = useState({});

  const {
    billetTax,
    creditCardTax,
    pixTax,
    policies: { invoice_details: invoiceDetails },
  } = useSelector((state: RootStateOrAny) => state.root);
  const { setClassrooms, addOfferRequest, fetchClassroomsSelectOptions } =
    shopAction;

  useEffect(() => {
    dispatch(setClassrooms());
    dispatch(fetchClassroomsSelectOptions());
  }, []);

  const {
    classrooms,
    isLoading,
    isLoadingClassrooms,
    sending,
    classroomsSelectOptions,
  } = useSelector((state: ShopStoreProps) => state.shop);

  const informationTabForm: InformationTabForm = {
    recipient_wallet_id: null,
    title: undefined,
    image: undefined,
    description: undefined,
    max_quantity_per_order: 9,
    size: undefined,
    has_stock: false,
    quantity_stock: undefined,
  };

  const offerTabForm: OfferTabForm = {
    original_price: 0,
    sell_period: null,
    allowed_payment_method: [
      ...ALLOWED_PAYMENT_METHOD,
      ...(invoiceDetails?.credit_card_enabled ? ['credit_card'] : []),
    ],
    max_installments: 1,
    days_to_expire_payment: 5,
    enabled_notification_types: ['email', 'push'],
    absorb_tax: false,
  };

  const recipientTabForm: RecipientsTabForm = {
    classroom_ids:
      classrooms
        ?.map((classroom) => classroom.options.map((option) => option.id))
        .flat() || [],
    classroom_with_student_profile_ids: {},
    sent_kind: 'classroom',
  };

  const getFormMeta = (): FormMeta => ({
    status: 'not_started',
    billetTax,
    creditCardTax,
    pixTax,
    meta_max_installments: NEW_OFFER_MAX_INSTALLMENTS,
    classrooms: classrooms,
    select_options: {
      ...classroomsSelectOptions,
      max_installments: INSTALLMENTS_SCHOOLPRODUCT,
    },
  });

  const handleOnSubmit = (formContext) => {
    const offer = normalizeParams({ form: formContext.form, action: 'new' });

    setLatestOffer(offer);
    setIsConfirmModalOpen(true);
  };

  const handleSave = () => {
    setIsConfirmModalOpen(false);
    dispatch(addOfferRequest(latestOffer));
  };

  if (isLoading || isLoadingClassrooms) return <Loader />;

  return (
    <div>
      <Loader isLoading={isLoading}>
        <OfferForm
          action="new"
          form={{ ...informationTabForm, ...offerTabForm, ...recipientTabForm }}
          formMeta={getFormMeta()}
          isSubmitting={sending}
          onSubmit={handleOnSubmit}
          backTo="/schools/school_products/tab/shop"
          titlePage="Nova oferta do Shop"
          titleModal="Descartar preenchimento"
          contentModal="O cadastro da oferta será cancelado e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        />
        <Toast />
        <AlertModal
          type="success"
          title="Criar oferta no shop"
          isOpen={isConfirmModalOpen}
          showBackButton
          confirmButtonText="Criar oferta"
          cancelButtonText="Cancelar"
          onConfirm={handleSave}
          closeAlert={() => setIsConfirmModalOpen(false)}
        >
          {TEXT_CONFIRMATION_MODAL}
        </AlertModal>
      </Loader>
    </div>
  );
};

export default NewOffer;
