import moment from 'moment';
import { NormalizeParams } from 'components/Payments/Shop/OfferForm/types';

const getStatus = (startDate: string, endDate: string) => {
  const isAfterOfferStart = moment().isSameOrAfter(moment(startDate));
  const isBeforeOfferEnd = moment().isSameOrBefore(moment(endDate));

  if (!isAfterOfferStart) return 'not_started';
  if (isAfterOfferStart && isBeforeOfferEnd) return 'active';
};

const normalizeParams = ({ form, action }: NormalizeParams) => {
  const data = new FormData();
  const students = form.classroom_with_student_profile_ids;

  const original_price_cents = form.original_price * 100 + '';
  const status =
    action === 'edit'
      ? form.status
      : getStatus(form.sell_period.startDate, form.sell_period.endDate);

  data.append(
    'offer[recipient_wallet_id]',
    form.recipient_wallet_id.toString()
  );
  data.append('offer[sent_kind]', form.sent_kind);
  data.append('offer[status]', status);
  data.append('offer[title]', form.title);
  if (form.image && form.image.uploaded === false) {
    data.append('offer[image]', form.image);
  }
  data.append('offer[original_price_cents]', original_price_cents);
  data.append('offer[starts_at]', form.sell_period.startDate);
  data.append('offer[expires_at]', `${form.sell_period.endDate} 23:59:00`);
  form.allowed_payment_method.forEach((method) => {
    data.append('offer[allowed_payment_methods][]', method);
  });
  form.classroom_ids.forEach((classroom) => {
    data.append('offer[classroom_ids][]', classroom);
  });
  form.enabled_notification_types.forEach((type) => {
    data.append('offer[enabled_notification_types][]', type);
  });
  data.append('offer[description]', form.description);
  data.append('offer[absorb_tax]', 'true');
  data.append('offer[max_installments]', form.max_installments.toString());
  data.append(
    'offer[max_quantity_per_order]',
    form.max_quantity_per_order.toString()
  );
  if (!!form.size) data.append('offer[options][size]', form.size);
  data.append(
    'offer[days_to_expire_payment]',
    form.days_to_expire_payment.toString()
  );
  data.append('offer[settings][absorb_tax]', !!form.absorb_tax);
  data.append('offer[has_stock]', form.has_stock);
  if (!!form.has_stock)
    data.append('offer[quantity_stock]', form.quantity_stock);

  Object.keys(students).forEach((classroomId) => {
    students[classroomId].forEach((studentId) => {
      data.append(
        `offer[classroom_with_student_profile_ids][${classroomId}][]`,
        studentId
      );
    });
  });

  return data;
};

export default normalizeParams;
